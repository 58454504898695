<template>
    <div class="table-box">
        <el-table
            ref="multipleTable"
            :data="tableData"
            height="100%"
            :header-cell-style="{
                background: '#FAFAFA',
                color: '#333333',
                'font-size': '14px',
                'font-weight': '500',
            }"
        >
            <el-table-column width="8"> </el-table-column>
            <el-table-column
                prop="createTime"
                label="创建时间"
                show-overflow-tooltip
                width="93"
            >
                <template slot-scope="scope">
                    <el-button
                        type="text"
                        style="color: #2370eb"
                        @click="onDetail(scope.row)"
                        >{{
                            $tableDataHandle.createTime({
                                createTime: scope.row.createTime,
                            })
                        }}</el-button
                    >
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column prop="updateTime" label="联系方式" width="180">
                <template slot="header">
                    <div>&nbsp;&nbsp;联系方式</div>
                </template>
                <template slot-scope="scope">
                    <div
                        v-if="
                            (scope.row.lineContactBOList &&
                                scope.row.lineContactBOList.length > 0) ||
                            (scope.row.customerMainContactBOList &&
                                scope.row.customerMainContactBOList.length > 0)
                        "
                    >
                        <el-tooltip
                            v-if="
                                scope.row.lineContactBOList &&
                                scope.row.lineContactBOList.length > 0
                            "
                            class="item"
                            effect="dark"
                            placement="bottom"
                        >
                            <div slot="content" class="way_box">
                                <p
                                    v-for="item in scope.row.lineContactBOList"
                                    :key="item.id"
                                >
                                    <span class="way_box_fs">
                                        <em>
                                            {{
                                                $tableDataHandle.contactWay(
                                                    item
                                                )
                                            }}</em
                                        >
                                        {{ item.contactContent }}
                                    </span>
                                    <span
                                        class="copy2"
                                        v-if="item.number > 1"
                                        @click="telSearch(item)"
                                    >
                                        <i
                                            class="iconfont icon-copy-rect-full"
                                        ></i>
                                        {{ item.number }}
                                    </span>
                                </p>
                            </div>
                            <div style="position: relative">
                                <span>
                                    <i
                                        :class="
                                            $tableDataHandle.contactWayColor(
                                                returnWay(scope.row)
                                            ).icon
                                        "
                                        :style="{
                                            color: $tableDataHandle.contactWayColor(
                                                returnWay(scope.row)
                                            ).color,
                                        }"
                                    ></i>
                                    &nbsp;&nbsp;{{
                                        returnWay(scope.row).contactContent
                                    }}
                                </span>
                                <span
                                    class="copy"
                                    v-if="returnWay(scope.row).number > 1"
                                    @click="telSearch(returnWay(scope.row))"
                                >
                                    <i class="iconfont icon-copy-rect-full"></i>
                                    {{ returnWay(scope.row).number }}
                                </span>
                            </div>
                        </el-tooltip>
                        <el-tooltip
                            v-else-if="
                                scope.row.customerMainContactBOList &&
                                scope.row.customerMainContactBOList.length > 0
                            "
                            class="item"
                            effect="dark"
                            placement="bottom"
                        >
                            <div slot="content" class="way_box">
                                <p
                                    v-for="item in scope.row
                                        .customerMainContactBOList"
                                    :key="item.id"
                                >
                                    <span class="way_box_fs">
                                        <em>
                                            {{
                                                $tableDataHandle.contactWay(
                                                    item
                                                )
                                            }}</em
                                        >
                                        <em class="contactContent">{{
                                            item.contactContent
                                        }}</em>
                                        &nbsp;&nbsp;
                                        {{ item.contactName }}
                                    </span>
                                </p>
                            </div>
                            <div style="position: relative">
                                <span>
                                    <i
                                        :class="
                                            $tableDataHandle.contactWayColor(
                                                scope.row
                                                    .customerMainContactBOList[0]
                                            ).icon
                                        "
                                        :style="{
                                            color: $tableDataHandle.contactWayColor(
                                                scope.row
                                                    .customerMainContactBOList[0]
                                            ).color,
                                        }"
                                    ></i>
                                    &nbsp;&nbsp;{{
                                        scope.row.customerMainContactBOList[0]
                                            .contactContent
                                    }}
                                </span>
                            </div>
                        </el-tooltip>
                    </div>
                    <div v-else>- -</div>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="线索产品" width="100" show-overflow-tooltip>
                <template slot-scope="scope">
                    <div>
                        {{
                            $tableDataHandle.productCategory(
                                scope.row.productClue
                            )
                        }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <!-- <el-table-column label="线索类型" show-overflow-tooltip>
                <template slot-scope="scope">
                    <div>
                        {{ $tableDataHandle.clueType(scope.row.clueType) }}
                    </div>
                </template>
            </el-table-column> -->
            <el-table-column label="客户名称" width="239" show-overflow-tooltip>
                <template slot-scope="scope">
                    <div>
                        {{ scope.row.companyName || '- -' }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="需求" width="173" show-overflow-tooltip>
                <template slot-scope="scope">
                    <div>
                        {{ scope.row.remark || '- -' }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="分配部门" width="80" show-overflow-tooltip>
                <template slot-scope="scope">
                    {{ scope.row.followDepartmentName || '- -' }}
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column
                prop="remark"
                width="55"
                label="负责人"
                show-overflow-tooltip
            >
                <template slot-scope="scope">
                    <div>
                        {{ scope.row.followAdminName || '- -' }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column
                prop="remark"
                label="状态"
                width="61"
                show-overflow-tooltip
            >
                <template slot-scope="scope">
                    <div>
                        {{ $tableDataHandle.clueStatus(scope.row.status) }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column
                prop="remark"
                label="操作"
                fixed="right"
                width="130"
            >
                <template slot-scope="scope">
                    <el-button
                        style="color: #2370eb"
                        v-if="btnP.receive"
                        type="text"
                        @click="onReceive(scope.row)"
                    >
                        领取
                    </el-button>
                    <el-button
                        style="color: #2370eb"
                        v-if="btnP.disUser"
                        type="text"
                        @click="onDistributionUser(scope.row)"
                    >
                        分配负责人
                    </el-button>
                </template>
            </el-table-column>
            <div slot="empty" class="empty">
                <img src="@/assets/zanwushuju.png" />
                <span style="display: block">暂无数据～</span>
            </div>
        </el-table>
    </div>
</template>
<script>
import { clueList } from '@/api/user/clue';
export default {
    data() {
        return {
            tableData: [],
            btnP: {},
            choiceArr: [],
            isChiose: false,
            pageData: [],
        };
    },
    methods: {
        handleSelectionChange(val) {
            this.choiceArr = [];
            this.choiceArr = val;
            console.log(val);
            this.$emit('distributionDep', val);
        },
        // 获取列表
        getData(searchDate, dateChoice, searchVal, pageNum, pageSize, btnP) {
            this.btnP = btnP;
            let data = {
                param: {
                    statusList: [4, 15],
                    sourceCategory: 2,
                },
                pageNum: pageNum,
                pageSize: pageSize,
            };

            data.param.departmentId = sessionStorage.getItem('departmentId');

            if (searchVal.productClue) {
                data.param.productClue = searchVal.productClue;
            }
            if (searchVal.name) {
                data.param.name = searchVal.name;
            }
            if (dateChoice != '') {
                let date = new Date();
                data.param.endCreateDate = this.$searchTime.getNextDate(
                    date,
                    0
                );
                searchDate = [];
                if (dateChoice == '今天') {
                    data.param.startCreateDate = this.$searchTime.getNextDate(
                        date,
                        0
                    );
                } else if (dateChoice == '7天内') {
                    data.param.startCreateDate = this.$searchTime.getNextDate(
                        date,
                        7
                    );
                } else if (dateChoice == '本月') {
                    data.param.startCreateDate =
                        this.$searchTime.DateUtil.getStartDayOfMonth();
                    data.param.endCreateDate =
                        this.$searchTime.DateUtil.getEndDayOfMonth();
                }
            }
            if (searchDate && searchDate.length > 0) {
                data.param.startCreateDate = searchDate[0];
                data.param.endCreateDate = searchDate[1];
            }
            this.pageData = {
                searchDate: searchDate,
                dateChoice: dateChoice,
                searchVal: searchVal,
                pageNum: pageNum,
                pageSize: pageSize,
            };
            clueList(data).then((res) => {
                if (res.code == 200) {
                    this.tableData = res.data.list;
                    this.$emit('totalNub', res.data.total);
                }
            });
        },
        onDetail(row) {
            sessionStorage.setItem('pageData', JSON.stringify(this.pageData));
            this.$emit('onDetail', row);
        },
        onReceive(row) {
            this.$emit('onReceive', row);
        },
        onDistributionUser(row) {
            this.$emit('onDistributionUser', row);
        },
        telSearch(row) {
            console.log(row);
            this.$emit('telSearch', row);
        },
        returnWay(row) {
            let index = row.lineContactBOList.findIndex((item) => {
                return item.number > 0;
            });
            if (index == -1) {
                return row.lineContactBOList[0];
            } else {
                return row.lineContactBOList[index];
            }
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.table-box {
    width: 100%;
    height: 100%;
    .icon-dianhua {
        color: #2370eb;
    }
    .icon-weixin {
        color: #50b674;
    }
    .icon-QQ {
        color: #2370eb;
    }
    .icon-youxiang {
        color: #feb516;
    }
    .icon-qita {
        color: #13ab16;
    }
    .icon-yuyuebaifang {
        color: #f15641;
    }
    .icon-qita1 {
        color: #af90ff;
    }
    .tableBtn-box {
        display: flex;
        align-items: center;
        justify-items: center;
    }
    .tableBtn {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-items: center;
        margin-left: 10px;
        button {
            width: 20px;
            height: 20px;
            margin: 0;
            border-radius: 0;
            background: #fff;
            border: 1px solid #c9c9c9;
        }
        button:nth-child(1) {
            color: #2370eb;
            border-bottom: none;
        }
        button:nth-child(2) {
            color: #c9c9c9;
        }
    }
    /deep/ .disableheadselection > .cell .el-checkbox__inner {
        display: none;
    }
}
.copy {
    height: 20px;
    background: #fff1d9;
    border-radius: 20px;
    line-height: 20px;
    position: absolute;
    right: 0;
    top: 0;
    font-size: 12px;
    display: inline-block;
    padding: 0 8px 0 25px;
    color: #ff9c39;
    i {
        position: absolute;
        left: -10px;
        top: -10px;
        display: inline-block;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: #ff9c39;
        color: #fff;
        text-align: center;
        line-height: 40px;
        font-size: 20px;
        transform: scale(0.5);
    }
}
.copy2 {
    height: 20px;
    background: #fff1d9;
    border-radius: 20px;
    line-height: 20px;

    font-size: 12px;
    display: inline-block;
    padding: 0 8px 0 25px;
    color: #ff9c39;
    position: relative;
    i {
        position: absolute;
        left: -10px;
        top: -10px;
        display: inline-block;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: #ff9c39;
        color: #fff;
        text-align: center;
        line-height: 40px;
        font-size: 20px;
        transform: scale(0.5);
    }
}
.way_box_fs {
    line-height: 30px;
    em {
        display: inline-block;
        width: 30px;
        font-style: normal;
    }
    .contactContent {
        width: 120px !important;
        display: inline-block;
    }
}
</style>
<style lang="scss"></style>
